
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Address } from '@/store/persons/person'

@Component
  export default class AddressAlignment extends Vue {
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: '' }) appendOuter!: string;
  @Prop({ type: String, default: '' }) placeholder!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({
    type: Array,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    default: () => [],
  })
  directions!: Array<Address>;
  }
