import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export class BaseCustomForm extends Vue {
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: String, default: '' }) appendOuter!: string;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: Boolean, default: false }) innerShadow!: boolean;
  @Prop({ type: String, default: '' }) placeholder!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) isRut!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;
}
