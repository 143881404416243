
  import { Component, Prop } from 'vue-property-decorator'
  import { personalReferenceData } from '@/store/persons/person'
  import { BaseCustomForm } from '@/components/person/BaseAlignment'

@Component
  export default class ReferenceAlignment extends BaseCustomForm {
  @Prop({ type: Array, default: () => [] })
  referenceData!: Array<personalReferenceData>;
  }
