import { render, staticRenderFns } from "./AddressAlignment.vue?vue&type=template&id=79ca1292&"
import script from "./AddressAlignment.vue?vue&type=script&lang=ts&"
export * from "./AddressAlignment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VCombobox,VIcon})
